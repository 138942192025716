export default {
  init(vue) {
      return {
        query (data) {
          return vue.$http({
            method: 'GET',
            url: '/api/v1/rechargeCenter/manager/goods',
            params: data
          })
        },
        updateStatus (id, status) {
          return vue.$http({
            method: 'PUT',
            url: `/api/v1/rechargeCenter/manager/goods/${id}/status`,
            data: {
              status: status
            }
          })
        },
        findById (id) {
          return vue.$http({
            method: 'GET',
            url: `/api/v1/rechargeCenter/manager/goods/${id}`
          })
        },
        saveGoods(form) {
          return vue.$http({
            method: form._id ? 'PUT' : 'POST',
            url: `/api/v1/rechargeCenter/manager/goods`,
            data: form
          })
        }
      }
  }
}
