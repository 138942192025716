export default {
    init (vue) {
      return {
        query (params) {
          return vue.$http({
            method: 'GET',
            url: '/api/v1/qagames',
            params: params
          })
        },
        findById (id) {
          return vue.$http({
            method: 'GET',
            url: `/api/v1/qagames/${id}`
          })
        },
        save (qagame) {
          return vue.$http({
            method: 'POST',
            url: '/api/v1/qagames',
            data: qagame
          })
        },
        remove (qagame) {
          return vue.$http({
            method: 'DELETE',
            url: '/api/v1/qagames/qagame',
            data: qagame
          })
        }
      }
    }
  }
  