<template lang="pug">
  .redpackets
    el-row
      el-col(:span="12")
      el-col(:span="12")
        el-button(type="primary", @click="toForm") 添加商品
    el-table(:data="products", stripe, v-loading="loading")
      el-table-column(prop="update_date", label="更新日期")
        template(slot-scope="scope")
          span {{$moment(scope.row.update_date).calendar()}}
      el-table-column(label="ID", prop="_id")
      el-table-column(label="状态")
        template(slot-scope="scope")
          el-tag(type="success", v-if="scope.row.status === 1") 上架中
          el-tag(type="warning", v-else-if="scope.row.status === 0") 已下架
          span(v-else) -
      el-table-column(prop="product_url", label="办理地址")
      el-table-column(label="名称", prop="name")
      el-table-column(label="图片")
        template(slot-scope="scope")
          el-image.cover-img(
            :src="$STAITC_HOST + scope.row.cover_img + '!414_jpeg_zoom'",
            fit="cover",
            :preview-src-list="[$STAITC_HOST + scope.row.cover_img + '!710_jpeg_zoom']"
          )
      el-table-column(label="类型")
        template(slot-scope="scope")
          el-tag(type="primary", v-if="scope.row.package_type === 'FLOW'") 流量包
          el-tag(type="primary", v-else-if="scope.row.package_type === 'CARD'") 号卡
          span(v-else) -
      el-table-column(label="CODE", prop="ref_goods_code")
      el-table-column(label="CarrierID", prop="ref_carrier_id")
      el-table-column(label="操作", width="140px")
        template(slot-scope="scope")
          el-button-group
            el-button(size="mini", type="warning", @click="toForm(scope.row)") 修改
            el-popconfirm(v-if="scope.row.status === 0", title="确定上架？", @onConfirm="handleUpdateStatus(scope.row, 1)")
              el-button(size="mini", type="warning", slot="reference") 上架
            el-popconfirm(v-else-if="scope.row.status === 1", title="确定下架？", @onConfirm="handleUpdateStatus(scope.row, 0)")
              el-button(size="mini", type="primary", slot="reference") 下架
    el-pagination(
      background,
      :current-page="pageModel.pageNo",
      layout="prev, pager, next, total",
      :total="pageModel.total"
      @current-change="query"
    )
</template>

<script>

export default {
  data() {
    return {
      products: [],
      loading: false,
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        condition: null
      }
    }
  },
  mounted() {
    this.query(1)
  },
  methods: {
    query(pageNo) {
      this.pageModel.pageNo = pageNo || this.pageModel.pageNo
      this.loading = true
      this.$service.CarrierService
        .query(this.pageModel)
        .then(res => {
          if (res.status === 200) {
            this.pageModel.total = res.data.data.total
            this.products = res.data.data.products
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    toForm(product) {
      this.$router.push({
        name: 'CarrierProductsForm',
        query: product ? { id: product._id } : undefined
      })
    },
    handleUpdateStatus(product, status) {
      this.loading = true
      this.$service.CarrierService
        .updateProductStatus(product._id, status)
        .then(res => {
          if (res.status === 200) {
            this.$message.success('状态已更新')
            this.query()
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scope>
.cover-img{
  width: 60px;
  height: 60px;
}
</style>