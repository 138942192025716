import Goods from '../views/goods/list.vue'
import Form from '../views/goods/form.vue'
export default [
  {
    path: '/goods',
    name: "goods",
    component: Goods
  },
  {
    path: '/goods/form',
    name: "goodForm",
    component: Form,
  }
]