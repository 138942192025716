<template lang="pug">
  .idiomgame-logs
    el-table(:data="logs", stripe, v-loading="loading")
      el-table-column(prop="create_date", label="记录日期")
        template(slot-scope="scope")
          span {{$moment(scope.row.create_date).calendar()}}
      el-table-column(label="头像")
        template(slot-scope="scope")
          el-image.avatar(
            :src="scope.row.user.userInfo.avatarUrl",
            fit="cover",
            :preview-src-list="[scope.row.user.userInfo.avatarUrl]"
          )
      el-table-column(prop="user.userInfo.nickName", label="微信昵称")
      el-table-column(label="省份城市")
        template(slot-scope="scope")
          span {{scope.row.user.userInfo.province + '，' +  scope.row.user.userInfo.city}}
      el-table-column(prop="coin", label="获得陀螺币")
    el-pagination(
      background,
      :current-page="pageModel.pageNo",
      layout="prev, pager, next, total",
      :total="pageModel.total"
      @current-change="query"
    )
</template>

<script>

export default {
  data() {
    return {
      logs: [],
      loading: false,
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        condition: null
      }
    }
  },
  mounted() {
    this.query(1)
  },
  methods: {
    query(pageNo) {
      this.pageModel.pageNo = pageNo || this.pageModel.pageNo
      this.loading = true
      this.$service.IdiomGameService
        .queryLogs(this.pageModel)
        .then(res => {
          if (res.status === 200) {
            this.pageModel.total = res.data.data.total
            this.logs = res.data.data.logs
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scope>
.avatar{
  width: 36px;
  height: 36px;
}
</style>