export default {
  init (vue) {
    return {
      query (params) {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/goods/manager',
          params: params
        })
      },
      save (data) {
        let _method = 'POST'
        if (data._id) {
          _method = 'PUT'
        }
        return vue.$http({
          method: _method,
          url: '/api/v1/goods',
          data: data
        })
      },
      updateRuleGroup (good, group) {
        return vue.$http.put(`/api/v1/goods/${good}/rulegroup/${group}`)
      },
      updateSort (good, sort) {
        return vue.$http.put(`/api/v1/goods/${good}/sort/${sort}`)
      },
      findById (id) {
        return vue.$http.get(`/api/v1/goods/${id}`)
      },
      updateCoin (id, coin) {
        return vue.$http.put(`/api/v1/goods/${id}/coin`, {
          coin: coin
        })
      },
      updateVisualSales (id, v_sales_count) {
        return vue.$http.put(`/api/v1/goods/${id}/visualsales`, {
          v_sales_count: v_sales_count
        })
      },
      syncSales (id) {
        return vue.$http.put(`/api/v1/orders/${id}/syncsales`)
      },
      updateStatus (id, status) {
        return vue.$http.put(`/api/v1/goods/${id}/status`, {
          status: status
        })
      },
      updateCanBargain (id, canBargain) {
        return vue.$http.put(`/api/v1/goods/${id}/canbargain`, {
          can_bargain: canBargain
        })
      },
      getById (goodId) {
        return vue.$http({
          method: 'GET',
          url: `/api/v1/goods/${goodId}`
        })
      }
    }
  }
}
