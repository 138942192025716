<template lang="pug">
  .redpacket-logs
    el-table(:data="redPacketLogs", stripe, v-loading="loading")
      el-table-column(prop="create_date", label="创建日期")
        template(slot-scope="scope")
          span {{$moment(scope.row.create_date).calendar()}}
      el-table-column(label="用户头像")
        template(slot-scope="scope")
          el-image.avatar(
            :src="scope.row.user.userInfo.avatarUrl",
            fit="cover",
            :preview-src-list="[scope.row.user.userInfo.avatarUrl]"
          )
      el-table-column(prop="user.userInfo.nickName", label="用户微信昵称")
      el-table-column(label="红包名称", prop="name")
      el-table-column(label="备注", prop="memo")
      el-table-column(label="红包金额（分）", prop="amount")
        template(slot-scope="scope")
          el-tag(type="danger") {{scope.row.amount}}
      el-table-column(label="外部订单号", prop="trade_no")
    el-pagination(
      background,
      :current-page="pageModel.pageNo",
      layout="prev, pager, next, total",
      :total="pageModel.total"
      @current-change="query"
    )
</template>

<script>

export default {
  data() {
    return {
      redPacketLogs: [],
      loading: false,
      redPacketId: '',
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        condition: {
          redpacket: ''
        }
      }
    }
  },
  mounted() {
    this.pageModel.condition.redpacket = this.$route.params.id
    this.query(1)
  },
  methods: {
    query(pageNo) {
      this.pageModel.pageNo = pageNo || this.pageModel.pageNo
      this.loading = true
      this.$service.RedPacketService
        .queryLogs(this.pageModel)
        .then(res => {
          if (res.status === 200) {
            this.pageModel.total = res.data.data.total
            this.redPacketLogs = res.data.data.redPacketLogs
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scope>
</style>