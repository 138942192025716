<template lang="pug">
  .notices
    el-row
      el-col(:span="12")
      el-col(:span="12")
        el-button(type="primary", @click="toForm") 添加公告
    el-table(:data="notices", stripe, v-loading="loading")
      el-table-column(prop="update_date", label="更新日期")
        template(slot-scope="scope")
          span {{$moment(scope.row.update_date).calendar()}}
      el-table-column(prop="create_date", label="创建日期")
        template(slot-scope="scope")
          span {{$moment(scope.row.create_date).calendar()}}
      el-table-column(label="状态")
        template(slot-scope="scope")
          el-tag(type="success", v-if="scope.row.status === 1") 正常
          el-tag(type="warning", v-else-if="scope.row.status === 0") 关闭
          span(v-else) -
      el-table-column(prop="title", label="标题")
      el-table-column(prop="content", label="公告内容")
      el-table-column(label="接收目标")
        template(slot-scope="scope")
          span(v-if="scope.row.target_type === 'all'") 所有用户
          span(v-else-if="scope.row.target_type === 'user'") 指定用户
      el-table-column(label="阅读统计(次)", prop="read_count")
    el-pagination(
      background,
      :current-page="pageModel.pageNo",
      layout="prev, pager, next, total",
      :total="pageModel.total"
      @current-change="query"
    )
</template>

<script>
export default {
  name: 'Notices',
  data() {
    return {
      notices: [],
      loading: false,
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.query(1)
  },
  methods: {
    query(pageNo) {
      this.pageModel.pageNo = pageNo || this.pageModel.pageNo
      this.$service.NoticeService
        .query({
          pageNo: this.pageModel.pageNo,
          pageSize: this.pageModel.pageSize
        })
        .then(res => {
          if (res.status === 200) {
            this.pageModel.total = res.data.data.total
            this.notices = res.data.data.notices
          }
        })
    },
    toForm() {
      this.$router.push({ name: 'noticesForm' })
    }
  }
}
</script>

<style lang="less" scoped>

</style>