import RechargeCenter from '../views/otherSettings/rechargeCenter.vue'
import RechargeCenterForm from '../views/otherSettings/rechargeCenterGoods.form.vue'

export default [
  {
    path: '/otherSettings/rechargeCenter',
    name: 'rechargeCenter',
    component: RechargeCenter,
  },
  {
    path: '/otherSettings/rechargeCenter/form',
    name: 'rechargeCenterForm',
    component: RechargeCenterForm,
  }
]