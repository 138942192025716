<template lang="pug">
  .notice-form
    el-form(:model="form", label-width="100px")
      el-form-item(label="标题")
        el-input.mid-width(v-model="form.title")
      el-form-item(label="公告内容")
        el-input(v-model="form.content", placeholder="描述", type="textarea")
      el-form-item(label="接收目标")
        el-select(v-model="form.target_type", placeholder="接收目标")
          el-option(label="所有用户", value="all")
          el-option(label="指定用户", value="user")
      el-form-item(label="接收用户", v-show="form.target_type === 'user'")
        el-select(
          v-model="form.target_user",
          remote,
          filterable,
          reserve-keyword,
          placeholder="请输入关键词",
          :remote-method="handleWXFilterSearch",
          :loading="wxFitlerLoading",
          @change="handleWXSelected"
        )
          el-option(
            v-for="item in wxOptions"
            :key="item._id"
            :label="item.userInfo.nickName"
            :value="item._id"
          )
            el-image.select-avatar(:src="item.userInfo.avatarUrl")
            span {{item.userInfo.nickName}}
      el-form-item
        el-button(@click="$router.go(-1)") 取消
        el-button(type="primary", :loading="loading", @click="saveForm") 确定
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {
        title: '',
        content: '',
        target_type: 'all',
        target_user: null
      },
      userPageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      orderByWX: null,
      wxFitlerLoading: false,
      wxOptions: []
    }
  },
  mounted() {
  },
  methods: {
    handleWXFilterSearch(val) {
      if (val) {
        this.userPageModel.condition = {
          'userInfo.nickName': val
        }
      } else {
        this.userPageModel.condition = null
      }
      this.wxFitlerLoading = true
      this.$service.UserService
        .query(this.userPageModel)
        .then(res => {
          this.wxFitlerLoading = false
          if (res.status === 200 && res.data.code === 0) {
            this.userPageModel.total = res.data.data.total
            this.wxOptions = res.data.data.users
          }
        })
        .catch(() => {
          this.wxFitlerLoading = false
        })
    },
    handleWXSelected() {
    },
    saveForm() {
      if(this.form.target_type !== 'user') {
        this.form.target_user = null
      }
      this.$service.NoticeService
        .save(this.form)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              message: '公告已保存，用户即刻收到该公告',
              type: 'success'
            });
            this.$router.push({
              name: 'notices'
            })
          } else {
            this.$message({
              message: '保存过程中出现错误，message:' + res.data.message,
              type: 'error'
            });
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.notice-form{
  max-width: 600px;
}
.mid-width{
  width: 500px;
}
.cover-carousels{
  width: 375px;
  zoom: .8;
}
.mobile-editor{
  width: 375px;
  height: 690px;
  padding-bottom: 66px;
  border: 6px solid #333;
  border-top-width: 16px;
  border-bottom-width: 16px;
  border-radius: 12px;
  zoom: 0.8;
  line-height: normal;
}
#imageUpload{
  height: 0px;
  overflow: hidden;
}
#coverImageUpload{
  display: inline-block;
}
.cover-image{
  width: 375px;
  height: auto;
}
</style>