<template lang="pug">
  .qagame-form(title="问答表单")
    el-form(:model="form", label-width="140px")
      el-form-item(label="标题")
        el-col(:span="10")
          el-input(v-model="form.title")
        el-col(:span="10")
          el-form-item(label="发布日期")
            el-date-picker(v-model="form.publish_date", type="date", placeholder="请选择发布日期")
      el-form-item(label="内容")
        el-row(v-loading="uploadingImage")
          el-col(:span="10")
            el-upload#imageUpload(
              :action="uploadUrl",
              :headers="uploadHeader",
              :show-file-list="false",
              accept="image/png, image/jpeg, image/gif",
              :on-success="uploadSuccess",
              :on-error="uploadError",
              :before-upload="beforeUpload"
            )
            quill-editor.mobile-editor(v-model="form.content", ref="contentEditor", :options="contentEditorOpts")
          el-col(:span="10")
            el-form-item(label="答案")
              quill-editor.mobile-editor(v-model="form.answer", ref="answerEditor", :options="answerEditorOpts")
      el-form-item(label="海报图")
        el-image.poster-image-preview(v-if="form.poster_image", :src="$STAITC_HOST + form.poster_image")
        el-upload#posterImageUpload(
            :action="uploadUrl",
            :headers="uploadHeader",
            :show-file-list="false",
            accept="image/png, image/jpeg, image/gif",
            :on-success="posterUploadSuccess",
            :on-error="posterUploadError",
            :before-upload="posterBeforeUpload"
          )
          el-button(size="small" type="primary")
            span(v-if="form.poster_image") 点击替换
            span(v-else) 点击上传
      el-form-item
        el-button(@click="$router.go(-1)") 取消
        el-button(type="primary", :loading="loading", @click="saveForm") 确定
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{'header': 1}, {'header': 2}],               // custom button values
  [{'list': 'ordered'}, {'list': 'bullet'}],
  [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
  [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
  [{'direction': 'rtl'}],                         // text direction

  [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
  [{'header': [1, 2, 3, 4, 5, 6, false]}],

  [{'color': []}, {'background': []}],          // dropdown with defaults from theme
  [{'font': []}],
  [{'align': []}],
  ['link', 'image', 'video'],
  ['clean']                                         // remove formatting button
]

export default {
  components: {
    quillEditor
  },
  name: 'QagameForm',
  props: ['qagameId'],
  data() {
    return {
      loading: false,
      form: {
        _id: '',
        title: '',
        content: '',
        answer: '',
        poster_image: '',
        publish_date: new Date()
      },
      uploadHeader: {},
      uploadUrl: '/api/v1/upload?t=qagame',
      uploadingImage: false,
      currentUpload: 'content', // content || answer
      contentEditorOpts: {
        placeholder: '请输入问题内容',
        theme: 'bubble',
        modules: {
          toolbar: {
            container: toolbarOptions,  // 工具栏
            handlers: {
              'image': (value) => {
                  if (value) {
                    this.currentUpload = 'content'
                    document.querySelector('#imageUpload input').click()
                  } else {
                    this.quill.format('image', false);
                  }
              }
            }
          }
        }
      },
      answerEditorOpts: {
        placeholder: '请输入问题答案',
        theme: 'bubble',
        modules: {
          toolbar: {
            container: toolbarOptions,  // 工具栏
            handlers: {
              'image': (value) => {
                  if (value) {
                    this.currentUpload = 'answer'
                    document.querySelector('#imageUpload input').click()
                  } else {
                    this.quill.format('image', false);
                  }
              }
            }
          }
        }
      }
    }
  },
  mounted () {
    this.form._id = this.$route.query.id
    if (this.form._id) {
      this.findById()
    }
  },
  computed: {
    contentEditor() {
      return this.$refs.contentEditor.quill
    },
    answerEditor() {
      return this.$refs.answerEditor.quill
    }
  },
  methods: {
    findById() {
      this.loading = true
      this.$service.QagameService
        .findById(this.form._id)
        .then(res => {
          if (res.status === 200 && res.data.code === 0) {
            this.form = res.data.data
          } else {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    saveForm() {
      this.loading = true
      this.$service.QagameService
        .save(this.form)
        .then(res => {
          if (res.status === 201 && res.data.code === 0) {
            this.$message({
              message: '问答内容已保存',
              type: 'success'
            });
            this.$router.go(-1)
          } else {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 上传图片前
    beforeUpload() {
      this.uploadingImage = true
    },
    // 上传图片成功
    uploadSuccess(res) {
      // res为图片服务器返回的数据
      // 获取富文本组件实例
      let _quill = this.contentEditor
      if (this.currentUpload === 'answer') {
        _quill = this.answerEditor
      }
      // 如果上传成功
      if (res.code === 0 && res.data.path) {
          // 获取光标所在位置
          let length = _quill.getSelection().index;
          // 插入图片  res.info为服务器返回的图片地址
          _quill.insertEmbed(length, 'image', this.$QAGAME_STAITC_HOST + res.data.path)
          // 调整光标到最后
          _quill.setSelection(length + 1)
      } else {
          this.$message.error('图片插入失败')
      }
      // loading动画消失
      this.uploadingImage = false
    },
    // 上传图片失败
    uploadError() {
      this.uploadingImage = false
      this.$message.error('图片插入失败')
    },
    posterBeforeUpload () {
      this.uploadingImage = true
    },
    posterUploadSuccess (res) {
      if (res.code === 0 && res.data.path) {
          this.form.poster_image = res.data.path
          this.$message.success('海报图已上传')
      } else {
          this.$message.error('海报图上传失败')
      }
      this.uploadingImage = false
    },
    posterUploadError () {
      this.uploadingImage = false
      this.$message.error('海报图上传失败')
    }
  },
}
</script>

<style lang="less" scoped>
.mobile-editor{
  width: 375px;
  height: 690px;
  border: 6px solid #333;
  border-top-width: 16px;
  border-bottom-width: 16px;
  border-radius: 12px;
}
#imageUpload{
  height: 0px;
  overflow: hidden;
}
.poster-image-preview{
  width: 340px;
  height: 360px;
  zoom: 0.5;
}
</style>