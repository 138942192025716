<template lang="pug">
el-row.signin-form(ref="signinForm" v-if="!isMobile")
  el-col(:span="9", :offset="15")
    .signin
      .title 管理平台
      el-form(ref="form", :model="form")
        el-form-item
          el-input(v-model="form.mobile", placeholder="手机号")
            i.el-input__icon.el-icon-user(slot="prefix")
        el-form-item
          el-input(type="password", v-model="form.password", placeholder="密码")
            i.el-input__icon.el-icon-user(slot="prefix")
        el-form-item
          el-button.btn-signin(type="primary", @click="onSubmit", :loading="processing") 登录
        el-form-item
          div(v-if="ip") Your IP: {{ip}}
el-row.ismobilewrap(v-else)
  .signin
    .title 陀螺CMS管理平台
    el-form(ref="form", :model="form")
      el-form-item
        el-input(v-model="form.mobile", placeholder="手机号")
          i.el-input__icon.el-icon-user(slot="prefix")
      el-form-item
        el-input(type="password", v-model="form.password", placeholder="密码")
          i.el-input__icon.el-icon-user(slot="prefix")
      el-form-item
        el-button.btn-signin(type="primary", @click="onSubmit", :loading="processing") 登录
      el-form-item
        div(v-if="ip") Your IP: {{ip}}
</template>

<script>

export default {
  name: 'signin',
  data() {
    return {
      isMobile: ('ontouchstart' in document.documentElement),
      form: {
        mobile: '',
        password: ''
      },
      ip: '',
      processing: false
    }
  },
  mounted() {
    document.querySelector('.signin-form').style.height = window.innerHeight + 'px'
    this.getIP()
  },
  methods: {
    getIP() {
      this.$service.CommonService
        .getIP()
        .then(res => {
          this.ip = res.data.data
        })
    },
    onSubmit() {
      this.processing = true
      this.$service.AuthService
        .signin(this.form)
        .then(res => {
          if (res.data.code === 0) {
            localStorage.setItem('access_token', res.data.data.access_token)
            this.$router.push('/onlineOrder/members')
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            })
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.$message({
              message: '账号或密码错误，请输入正确的用户名密码',
              type: 'error'
            })
          } else {
            this.$message(err.message)
          }
        })
        .finally(() => {
          this.processing = false
        })
    }
  }
}
</script>

<style lang="less" scope>
.signin-form {
  background: url('../../public/images/login-icon2.c9bb548.png') no-repeat left top;
  background-size: 780px 680px;
  min-height: 680px;
}

.signin {
  margin-top: 200px;
  width: 400px;
  padding: 24px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 16px #e3e3e3;

  .title {
    color: #409eff;
    font-size: 20px;
    text-align: center;
    margin: 24px 0;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .btn-signin {
    width: 100%;
  }

  .captcha {
    height: 30px;
  }

}

.ismobilewrap {
  .signin {
    width: 90%;
    padding: 24px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 16px #e3e3e3;
    box-sizing: border-box;
    margin: 50vw auto;

    .title {
      color: #409eff;
      font-size: 20px;
      text-align: center;
      margin: 24px 0;
      letter-spacing: 2px;
      font-weight: bold;
    }

    .btn-signin {
      width: 100%;
    }

    .captcha {
      height: 30px;
    }

  }
}
</style>
