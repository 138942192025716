<template lang="pug">
.orders
  el-row
    el-col(:span="12")
      el-form(:inline="true")
    el-col(:span="12")
      el-button(type="primary", @click="toForm") 添加图片
  el-table(:data="spotsGameList", stripe)
    el-table-column(label="创建日期")
      template(slot-scope="scope")
        span {{ $moment(scope.row.create_date).calendar() }}
    el-table-column(label="更新日期")
      template(slot-scope="scope")
        span {{ $moment(scope.row.update_date).calendar() }}
    el-table-column(prop="name", label="图片1")
      template(slot-scope="scope")
        img.preview-pic(
          :src="'https://tlhw.oss-cn-shanghai.aliyuncs.com/' + scope.row.pics[0]"
        )
    el-table-column(prop="name", label="图片2")
      template(slot-scope="scope")
        img.preview-pic(
          :src="'https://tlhw.oss-cn-shanghai.aliyuncs.com/' + scope.row.pics[1]"
        )
    el-table-column(label="操作")
      template(slot-scope="scope")
        el-button(type="primary", @click="removeItemFn(scope.row)") 删除
  el-pagination(
    background,
    :current-page="pageModel.pageNo",
    layout="prev, pager, next, total",
    :total="pageModel.total",
    @current-change="query"
  )
</template>

<script>
export default {
  data() {
    return {
      spotsGameList: [],
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        condition: {},
      },
    };
  },
  mounted() {
    this.query(1);
  },
  methods: {
    query(pageNo) {
      this.pageModel.pageNo = pageNo || this.pageModel.pageNo;
      let _condition = {};
      this.$service.SpotsGameService.query({
        pageNo: this.pageModel.pageNo,
        pageSize: this.pageModel.pageSize,
        condition: _condition,
      }).then((res) => {
        this.pageModel.total = res.data.data.total;
        this.spotsGameList = res.data.data.records;
      });
    },
    toForm(spots) {
      this.$router.push({
        name: "SpotsGameForm",
        query: spots ? { id: spots._id } : undefined,
      });
    },
    removeItemFn(item) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // eslint-disable-next-line
          // console.log(item._id);
          this.$service.SpotsGameService.removeItem(item._id)
            .then((res) => {
              if (res.data.code === 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.query(this.pageModel.pageNo);
              } else {
                this.$message({
                  message: res.data.message,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              this.$message.error(err.message);
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less" scope>
.cover-image {
  width: 60px;
  height: 40px;
}
.link {
  cursor: pointer;
}
.preview-pic {
  width: 150px;
  height: 86px;
}
</style>