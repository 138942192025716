export default {
  init(vue) {
      return {
        query (data) {
          return vue.$http({
            method: 'POST',
            url: '/api/v1/alipay/manager/transfer/logs',
            data: data
          })
        },
        transfer (data) {
          return vue.$http({
            method: 'POST',
            url: '/api/v1/alipay/manager/transfer',
            data: data
          })
        }
      }
  }
}
