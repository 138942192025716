<template lang="pug">
  el-dialog(title="订单状态", :visible.sync="dialogVisible")
    el-form(:model="form", label-width="80px")
      el-form-item(label="状态")
        el-select(v-model="form.status", placeholder="请选择订单状态")
          el-option(
            v-for="(item, key) in $orderStatus",
            :key="key",
            :label="item",
            :value="key * 1"
          )
    div(slot="footer")
      el-button(@click="dialogVisible = false") 取消
      el-button(type="primary", @click="saveForm", :loading="loading") 确定
</template>

<script>
export default {
  name: 'PaidOrderStatusForm',
  props: ['orderId'],
  data() {
    return {
      dialogVisible: false,
      loading: false,
      form: {},
      labelWidth: '130px'
    }
  },
  watch: {
    dialogVisible(){
      if (this.dialogVisible) {
        this.form.status = null
        this.$service.PaidOrderService
          .findById(this.orderId)
          .then(res => {
            if (res.status === 200 && res.data.code === 0 && res.data.data) {
              this.form = res.data.data
            }
          })
      }
    }
  },
  methods: {
    saveForm() {
      this.loading = true
      this.$service.PaidOrderService
        .updateStatus(this.orderId, this.form.status)
        .then(res => {
          if (res.status === 200 && res.data.code === 0) {
            this.$message({
              message: '状态已保存',
              type: 'success'
            });
            this.hide()
            this.$emit('saved')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    show() {
      this.dialogVisible = true
    },
    hide() {
      this.dialogVisible = false
    }
  },
}
</script>