export default {
  init (vue) {
    return {
      signin (data) {
        return vue.$http({
          method: 'POST',
          url: '/api/auth',
          data: data
        })
      }
    }
  }
}
