<template lang="pug">
  el-dialog(title="修改虚拟销售量", :visible.sync="dialogVisible")
    el-form(:model="form", label-width="80px")
      el-form-item(label="拟销售量")
        el-input-number(v-model="form.v_sales_count", label="拟销售量", controls-position="right", :step="100")
    div(slot="footer")
      el-button(@click="dialogVisible = false") 取消
      el-button(type="primary", @click="saveForm", :loading="loading") 确定
</template>

<script>
export default {
  name: 'VisualSalesForm',
  props: ['goodId'],
  data() {
    return {
      dialogVisible: false,
      loading: false,
      form: {
        v_sales_count: 1000
      },
      labelWidth: '130px'
    }
  },
  watch: {
    dialogVisible(){
      if (this.dialogVisible) {
        this.form.v_sales_count = 1000
        this.$service.GoodService
          .findById(this.goodId)
          .then(res => {
            if (res.status === 200 && res.data.code === 0 && res.data.data) {
              this.form = res.data.data
            }
          })
      }
    }
  },
  methods: {
    saveForm() {
      this.loading = true
      this.$service.GoodService
        .updateVisualSales(this.goodId, this.form.v_sales_count)
        .then(res => {
          if (res.status === 200 && res.data.code === 0) {
            this.$message({
              message: '虚拟销量已更新',
              type: 'success'
            });
            this.hide()
            this.$emit('saved')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    show() {
      this.dialogVisible = true
    },
    hide() {
      this.dialogVisible = false
    }
  },
}
</script>
