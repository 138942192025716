export default {
  init (vue) {
    return {
      query (data) {
        return vue.$http({
          method: 'POST',
          url: `/api/v1/eventtrack/query`,
          data: data
        })
      }
    }
  }
}
