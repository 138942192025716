export default {
  init (vue) {
    return {
      query (params) {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/statslogs',
          params: params
        })
      }
    }
  }
}
