import Qagames from '../views/qagames/list.vue'
import QagameForm from '../views/qagames/form.vue'

export default [
  {
    path: '/qagames',
    name: "qagames",
    component: Qagames
  },
  {
    path: '/qagames/form',
    name: "qagames.form",
    component: QagameForm
  }
]