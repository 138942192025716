import RedPackets from '../views/redpackets/list.vue'
import RedPacketLogs from '../views/redpackets/logs.vue'

export default [
  {
    path: '/redpackets',
    name: "redpackets",
    component: RedPackets
  },
  {
    path: '/redpackets/logs/:id',
    name: "redpacketLogs",
    component: RedPacketLogs
  }
]