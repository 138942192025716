<template lang="pug">
.orders
  el-table(:data="users", stripe, @sort-change="handleSort", v-loading="loading")
    el-table-column(sortable="custom", prop="create_date", label="注册日期")
      template(slot-scope="scope")
        span {{$moment(scope.row.create_date).calendar()}}
    el-table-column(label="头像")
      template(slot-scope="scope")
        el-image.avatar(
          :src="scope.row.userInfo.avatarUrl",
          fit="cover",
          :preview-src-list="[scope.row.userInfo.avatarUrl]"
        )
    el-table-column(sortable="custom", prop="userInfo.nickName", label="微信昵称")
    el-table-column(label="性别")
      template(slot-scope="scope")
        span(v-if="scope.row.userInfo.gender === 1") 男
        span(v-else-if="scope.row.userInfo.gender === 2") 女
        span(v-else) 未知
    el-table-column(sortable="custom", prop="coin", label="陀螺币")
    el-table-column(sortable="custom", prop="sign", label="签到数")
    el-table-column(sortable="custom", prop="invite", label="邀请成功数")
    el-table-column(label="省份城市")
      template(slot-scope="scope")
        span {{scope.row.userInfo.province + '，' +  scope.row.userInfo.city}}
  el-pagination(
    background,
    :current-page="pageModel.pageNo",
    layout="prev, pager, next, total",
    :total="pageModel.total"
    @current-change="query"
  )
</template>

<script>

export default {
  data() {
    return {
      users: [],
      loading: false,
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        sort: {
          coin: -1
        }
      }
    }
  },
  mounted() {
    this.query(1)
  },
  methods: {
    query(pageNo) {
      this.pageModel.pageNo = pageNo || this.pageModel.pageNo
      this.loading = true
      this.$service.UserService
        .query(this.pageModel)
        .then(res => {
          if (res.status === 200) {
            this.pageModel.total = res.data.data.total
            res.data.data.users.forEach((item) => {
              item.userInfo = item.userInfo || {}
            })
            this.users = res.data.data.users
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleSort(column) {
      // eslint-disable-next-line
      if (column.order) {
        this.pageModel.sort = {}
        this.pageModel.sort[column.prop] = column.order === 'ascending' ? 1 : -1
      } else {
        this.pageModel.sort = {
          coin: -1
        }
      }
      this.query(1)
    }
  }
}
</script>

<style lang="less" scope>
.avatar{
  width: 36px;
  height: 36px;
}
</style>