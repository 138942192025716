<template lang="pug">
  .records
    el-row
      el-col
        el-form(:inline="true")
          el-form-item(label="任务分类")
            el-select(v-model="taskTypeSelected", placeholder="请选择", clearable, @change="handleTaskTypeSelected")
              el-option(v-for="(val, key) in $taskTypes", :key="key", :label="val", :value="key")
          el-form-item(label="微信号")
            el-select(
              v-model="taskByUser",
              remote,
              filterable,
              reserve-keyword,
              placeholder="请输入关键词",
              :remote-method="handleWXFilterSearch",
              :loading="wxFitlerLoading",
              @change="handleWXSelected"
            )
              el-option(
                v-for="item in wxOptions"
                :key="item._id"
                :label="item.userInfo.nickName"
                :value="item._id"
              )
                el-image.select-avatar(:src="item.userInfo.avatarUrl")
                span {{item.userInfo.nickName}}
    el-table(:data="records", stripe, v-loading="loading")
      el-table-column(prop="create_date", label="记录日期")
        template(slot-scope="scope")
          span {{$moment(scope.row.create_date).calendar()}}
      el-table-column(label="头像")
        template(slot-scope="scope")
          el-image.avatar(
            :src="scope.row.user.userInfo.avatarUrl",
            fit="cover",
            :preview-src-list="[scope.row.user.userInfo.avatarUrl]"
          )
      el-table-column(prop="user.userInfo.nickName", label="微信昵称")
      el-table-column(label="省份城市")
        template(slot-scope="scope")
          span {{scope.row.user.userInfo.province + '，' +  scope.row.user.userInfo.city}}
      el-table-column(label="任务分类")
        template(slot-scope="scope")
          el-tag(size="mini") {{$taskTypes[scope.row.task_type]}}
      el-table-column(prop="coin", label="获得陀螺币")
    el-pagination(
      background,
      :current-page="pageModel.pageNo",
      layout="prev, pager, next, total",
      :total="pageModel.total"
      @current-change="query"
    )
</template>

<script>

export default {
  data() {
    return {
      taskByUser: null,
      records: [],
      loading: false,
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        condition: null
      },
      taskTypeSelected: null,
      userPageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      wxFitlerLoading: false,
      wxOptions: []
    }
  },
  mounted() {
    this.query(1)
  },
  methods: {
    query(pageNo) {
      this.pageModel.pageNo = pageNo || this.pageModel.pageNo
      this.loading = true
      this.$service.TaskService
        .queryRecords(this.pageModel)
        .then(res => {
          if (res.status === 200) {
            this.pageModel.total = res.data.data.total
            res.data.data.records.forEach((item) => {
              item.user.userInfo = item.user.userInfo || {}
            })
            this.records = res.data.data.records
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleTaskTypeSelected(val) {
      if (val) {
        this.pageModel.condition = {
          task_type: val
        }
      } else {
        this.pageModel.condition = null
      }
      this.query(1)
    },
    handleWXFilterSearch(val) {
      if (val) {
        this.userPageModel.condition = {
          'userInfo.nickName': val
        }
      } else {
        this.userPageModel.condition = null
      }
      this.wxFitlerLoading = true
      this.$service.UserService
        .query(this.userPageModel)
        .then(res => {
          this.wxFitlerLoading = false
          if (res.status === 200 && res.data.code === 0) {
            this.userPageModel.total = res.data.data.total
            this.wxOptions = res.data.data.users
          }
        })
        .catch(() => {
          this.wxFitlerLoading = false
        })
    },
    handleWXSelected(val) {
      if (this.pageModel.condition === null) {
        this.pageModel.condition = {}
      }
      this.pageModel.condition.user = val
      this.query(1)
    }
  }
}
</script>

<style lang="less" scope>
.avatar{
  width: 36px;
  height: 36px;
}
</style>