<template lang="pug">
.spotsgame-form
  .points
    .point-item(v-for="(item, index) in points", :key="index") 坐标{{index + 1}}：{{item.x + ',' + item.y}}
  el-form(inline)
    el-form-item
      .pic
        img.game-pic(v-if="pic1", :src="$STAITC_HOST + pic1", @click="handleAddPoint")
        img.point-pic(@click="handleRemovePoint(index)", src="/images/quan.png", :style="`left: ${item.x}px; top: ${item.y}px;`", v-for="(item, index) in webPoints", :key="index")
      el-upload(
          :action="uploadUrl",
          :headers="uploadHeader",
          :show-file-list="false",
          accept="image/png, image/jpeg",
          :on-success="uploadSuccess1",
          :on-error="uploadError",
          :before-upload="beforeUpload"
        )
          el-button(v-if="!pic1").btn-thumb 上传图片
          el-button.btn-thumb(v-else) 替换图片
    el-form-item
      .pic
        img.game-pic(v-if="pic2", :src="$STAITC_HOST + pic2", @click="handleAddPoint")
        img.point-pic(@click="handleRemovePoint(index)", src="/images/quan.png", :style="`left: ${item.x}px; top: ${item.y}px;`", v-for="(item, index) in webPoints", :key="index")
      el-upload(
          :action="uploadUrl",
          :headers="uploadHeader",
          :show-file-list="false",
          accept="image/png, image/jpeg",
          :on-success="uploadSuccess2",
          :on-error="uploadError",
          :before-upload="beforeUpload"
        )
          el-button(v-if="!pic2").btn-thumb 上传图片
          el-button.btn-thumb(v-else) 替换图片
  el-button(type="primary", @click="handleSubmit", :loading="loading") 保存
</template>

<script>
const CENTER_POSITION_X = 350 / 2 - 30 /2
const CENTER_POSITION_Y = 200 / 2 - 30 /2
export default {
  name: 'SpotsGameForm',
  data () {
    return {
      pic1: '',
      pic2: '',
      points: [{x: -999, y: -999}, {x: -999, y: -999}, {x: -999, y: -999}, {x: -999, y: -999}, {x: -999, y: -999}, {x: -999, y: -999}, {x: -999, y: -999}, {x: -999, y: -999}],
      webPoints: [],
      uploadHeader: {},
      uploadUrl: '/api/v1/upload?t=spotsgame',
      picUploading: false,
      loading: false
    }
  },
  mounted() {
  },
  methods: {
    handleAddPoint(e) {
      if (!this.pic1 || !this.pic2) {
        this.$message.warning('请先上传图片')
        return
      }
      let _emptyIndex = -1
      this.webPoints.forEach((item, index) => {
        if(item.x === -999 && item.y === -999) {
          _emptyIndex = index
          return false
        }
      })
      // 15 为图标宽度30的一半
      const _position = {
        x: e.offsetX - 15,
        y: e.offsetY - 15
      }
      const _gamePoints = this.convertToGamePosition(_position)
      if (_emptyIndex >= 0) {
        this.$set(this.webPoints, _emptyIndex, {
          x: _position.x,
          y: _position.y
        })
        this.$set(this.points, _emptyIndex, {
          x: _gamePoints.x,
          y: _gamePoints.y
        })
      } else {
        const _nextIndex = this.webPoints.length
        if (_nextIndex < this.points.length) {
          this.webPoints.push(_position)
          this.points[_nextIndex].x = _gamePoints.x
          this.points[_nextIndex].y = _gamePoints.y
        }
      }
      
    },
    handleRemovePoint(index) {
      this.webPoints[index].x = -999
      this.webPoints[index].y = -999
      this.points[index].x = -999
      this.points[index].y = -999
    },
    uploadSuccess1(res) {
      if (res.code === 0 && res.data.path) {
          this.pic1 = res.data.path
      } else {
          this.$message.error('上传失败')
      }
      this.picUploading = false
    },
    uploadSuccess2(res) {
      if (res.code === 0 && res.data.path) {
          this.pic2 = res.data.path
      } else {
          this.$message.error('上传失败')
      }
      this.picUploading = false
    },
    uploadError() {
      this.picUploading = false
      this.$message.error('上传失败')
    },
    beforeUpload() {
      this.picUploading = true
    },
    handleSubmit() {
      let _finalPoints = []
      this.points.forEach(item => {
        if (item.x > -999 && item.y > -999) {
          _finalPoints.push(item)
        }
      })
      let _formData = {
        pics: [
          this.pic1,
          this.pic2
        ],
        points: _finalPoints
      }
      this.loading = true
      this.$service.SpotsGameService
        .save(_formData)
        .then(res => {
          if (res.status === 201 && res.data.code === 0) {
            this.$router.push({
              name: 'SpotsGame'
            })
          } else {
            this.$message({
              message: res.data.message,
              type: 'error'
            });
          }
          
        })
        .catch(err => {
          this.$message.error(err.message)
        })
        .finally(() => {
          this.loading = false
        })
    },
    convertToGamePosition(point) {
      return {
        x: point.x - CENTER_POSITION_X,
        y: -(point.y - CENTER_POSITION_Y)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.spotsgame-form{
  .pic{
    width: 350px;
    height: 200px;
    border: 1px solid #a3a3a3;
    position: relative;
    overflow: hidden;
    .game-pic{
      width: 100%;
      height: 100%;
    }
    .point-pic{
      width: 30px;
      height: 30px;
      position: absolute;
    }
  }
  .points{
    width: 100%;
    display: flex;
    margin-bottom: 2rem;
    .point-item{
      flex: 1;
    }
  }
}
</style>