<template lang="pug">
  .redpackets
    el-table(:data="redPackets", stripe, v-loading="loading")
      el-table-column(prop="update_date", label="更新日期")
        template(slot-scope="scope")
          span {{$moment(scope.row.update_date).calendar()}}
      el-table-column(prop="create_date", label="创建日期")
        template(slot-scope="scope")
          span {{$moment(scope.row.create_date).calendar()}}
      el-table-column(label="名称", prop="name")
      el-table-column(label="描述", prop="desc")
      el-table-column(label="红包金额（分）", prop="amount")
        template(slot-scope="scope")
          el-tag(type="danger") {{scope.row.amount}}
      el-table-column(label="操作")
        template(slot-scope="scope")
          router-link(:to="{ name: 'redpacketLogs', params: { id: scope.row._id}}")
            el-button(size="mini", type="primary") 发放记录
    el-pagination(
      background,
      :current-page="pageModel.pageNo",
      layout="prev, pager, next, total",
      :total="pageModel.total"
      @current-change="query"
    )
</template>

<script>

export default {
  data() {
    return {
      redPackets: [],
      loading: false,
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        condition: null
      }
    }
  },
  mounted() {
    this.query(1)
  },
  methods: {
    query(pageNo) {
      this.pageModel.pageNo = pageNo || this.pageModel.pageNo
      this.loading = true
      this.$service.RedPacketService
        .query(this.pageModel)
        .then(res => {
          if (res.status === 200) {
            this.pageModel.total = res.data.data.total
            this.redPackets = res.data.data.redPackets
          }
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scope>
</style>