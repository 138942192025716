<template lang="pug">
  .qagames
    el-row.line-space
      el-col
        el-button(type="primary", size="small", icon="el-icon-plus", @click="handleCreate") 添加问答
    el-table(:data="qagames", stripe)
      el-table-column(prop="_id", label="ID")
      el-table-column(label="发布日期")
        template(slot-scope="scope")
          span {{$moment(scope.row.publish_date).format('YYYY-MM-DD')}}
      el-table-column(prop="title", label="标题")
      el-table-column(label="已看答案人数")
        template(slot-scope="scope")
          span {{scope.row.users.length}}
      el-table-column(label="创建日期")
        template(slot-scope="scope")
          span {{$moment(scope.row.create_date).calendar()}}
      el-table-column
        template(slot-scope="scope")
          el-button(type="warning", size="small", @click="handleModify(scope.row)") 修改
          el-popconfirm(title="确定移除模板？", @onConfirm="handleRemove(scope.row)")
            el-button(slot="reference", type="danger", size="small") 删除
    el-pagination(
      background,
      :current-page="pageModel.pageNo",
      layout="prev, pager, next, total",
      :total="pageModel.total"
      @current-change="query"
    )
</template>

<script>
export default {
  data() {
    return {
      qagames: [],
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.query(1)
  },
  methods: {
    query(pageNo) {
      this.pageModel.pageNo = pageNo || this.pageModel.pageNo
      this.$service.QagameService
        .query(this.pageModel)
        .then(res => {
          if (res.status === 200) {
            this.pageModel.total = res.data.data.total
            this.qagames = res.data.data.qagames
          }
        })
    },
    handleCreate() {
      this.$router.push({
        name: 'qagames.form'
      })
    },
    handleModify(qagame) {
      this.$router.push({
        name: 'qagames.form',
        query: {
          id: qagame._id
        }
      })
    },
    handleRemove(qagame) {
      this.$service.QagameService
        .remove(qagame)
        .then(res => {
          if (res.status === 200) {
            this.$message.success('已删除')
            this.query()
          }
        })
    }
  }
}
</script>

<style lang="less" scope>
.avatar{
  width: 36px;
  height: 36px;
}
</style>