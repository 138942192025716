import Products from '../views/carriers/products.vue'
import ProductForm from '../views/carriers/productForm.vue'
export default [
  {
    path: '/carriers/producsts',
    name: "CarrierProducts",
    component: Products
  },
  {
    path: '/carriers/producsts/form',
    name: "CarrierProductsForm",
    component: ProductForm
  }
]