<template lang="pug">
  .submessage-templates
    el-table(:data="templates", stripe, v-loading="loading")
      el-table-column(prop="priTmplId", label="模板ID")
      el-table-column(prop="title", label="模板标题", width="100px")
      el-table-column(label="模板内容")
        template(slot-scope="scope")
          .template-content
            div(v-for="item in scope.row.content.split('\\n')", :key="item") {{item}}
      el-table-column(label="模板例子")
        template(slot-scope="scope")
          .template-content
            div(v-for="item in scope.row.example.split('\\n')", :key="item") {{item}}
      el-table-column(label="模版类型", width="100px")
        template(slot-scope="scope")
          el-tag(type="primary", v-if='scope.row.type === 2') 一次性订阅
          el-tag(type="primary", v-else-if='scope.row.type === 3') 为长期订阅
          el-tag(type="info", v-else) -
</template>

<script>
export default {
  name: 'SubMessageTemplates',
  data() {
    return {
      templates: [],
      loading: false
    }
  },
  mounted() {
    this.query()
  },
  methods: {
    query() {
      this.loading = true
      this.$service.SubMessageService
        .queryTemplates()
        .then(res => {
          this.templates = res.data.data
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.template-content{
  background-color: #1ba9b6;
  padding: 12px;
  color: #fff;
  border-radius: 12px;
}
</style>