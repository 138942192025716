export default [
    // 广告管理
    {
        path: '/flowManagement/advertisingManagement',
        name: "advertisingManagement",
        component: () => import('../views/flowManagement/advertisingManagement'),
    },
    // 任务中心
    // {
    //     path: '/flowManagement/taskCenter',
    //     name: "taskCenter",
    //     component: () => import('../views/flowManagement/taskCenter'),
    // }
]