export default {
  init (vue) {
    return {
      query (params) {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/paidorders/manager',
          params: params
        })
      },
      findById(id) {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/paidorders/manager/paidorder',
          params: {
            id: id
          }
        })
      },
      updateExpress (id, express) {
        return vue.$http({
          method: 'PUT',
          url: `/api/v1/paidorders/${id}/express`,
          data: express
        })
      },
      updateStatus (id, status) {
        return vue.$http({
          method: 'PUT',
          url: `/api/v1/paidorders/${id}/status`,
          data: {
            status: status
          }
        })
      },
      sendMsg(id) {
        return vue.$http({
          method: 'POST',
          url: `/api/v1/paidorders/${id}/sendmsg`
        })
      }
    }
  }
}
