import SpotsGame from '../views/spotsgame/list.vue'
import SpotsGameForm from '../views/spotsgame/form.vue'
export default [
  {
    path: '/spotsgame',
    name: "SpotsGame",
    component: SpotsGame
  },
  {
    path: '/spotsgame/form',
    name: "SpotsGameForm",
    component: SpotsGameForm
  }
]