<template lang="pug">
.rechargeCenterGoods-form
  el-form(:model="form", label-width="130px")
    el-form-item(label="商品标题")
      el-input.mid-width(v-model="form.title")
    el-form-item(label="商品子标题")
      el-input.mid-width(v-model="form.sub_title")
    el-form-item(label="商品类型")
      el-select(v-model="form.goods_type", placeholder="请选择商品类型")
        el-option(v-for="item in goodsTypeOpts", :key="item.value", :label="item.label", :value="item.value")
    el-form-item(label="支付类型")
      el-select(v-model="form.pay_type", placeholder="请选择支付类型")
        el-option(v-for="item in payTypeOpts", :key="item.value", :label="item.label", :value="item.value")
    el-form-item(label="标签")
      el-input.mid-width(v-model="form.tag")
    el-form-item(label="价格描述")
      el-input.mid-width(v-model="form.price_desc")
    el-form-item(label="价格")
      el-input.mid-width(type="number", v-model="form.price")
        template(slot="append") 元
    el-form-item(label="初始价格")
      el-input.mid-width(type="number", v-model="form.initial_price")
        template(slot="append") 元
    el-form-item(label="排序")
      el-input.mid-width(v-model="form.sort")
    el-form-item(label="业务参数")
      el-input.mid-width(v-for="key in Object.keys(form.biz_params)", :key="key", v-model="form.biz_params[key]")
        template(slot="prepend") {{ key }}
    el-form-item
      el-row(v-loading="uploadingImage")
        el-col(:span="10")
          el-upload#imageUpload(
            :action="uploadUrl",
            :headers="uploadHeader",
            :show-file-list="false",
            accept="image/png, image/jpeg, image/gif",
            :on-success="uploadSuccess",
            :on-error="uploadError",
            :before-upload="beforeUpload"
          )
          quill-editor.mobile-editor(v-model="form.summary_desc", ref="summaryDescEditor", :options="summaryDescEditorOpts")
        el-col(:span="10")
          quill-editor.mobile-editor(v-model="form.desc", ref="descEditor", :options="descEditorOpts")
    el-form-item
      el-button(@click="goList") 取消
      el-button(type="primary", @click="saveForm", :loading="loading") 确定
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],

  [{'header': 1}, {'header': 2}],               // custom button values
  [{'list': 'ordered'}, {'list': 'bullet'}],
  [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
  [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
  [{'direction': 'rtl'}],                         // text direction

  [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
  [{'header': [1, 2, 3, 4, 5, 6, false]}],

  [{'color': []}, {'background': []}],          // dropdown with defaults from theme
  [{'font': []}],
  [{'align': []}],
  ['link', 'image', 'video'],
  ['clean']                                         // remove formatting button
]
export default {
  name: 'RechargeCenterGoodsForm',
  components: {
    quillEditor
  },
  data() {
    return {
      loading: false,
      uploadingImage: false,
      coverUploading: false,
      form: {
        biz_params: {
          goods_code: "",
          carrier: "",
          carrier_id: 1,
          verify_code_len: 4
        }
      },
      labelWidth: '130px',
      uploadHeader: {},
      uploadUrl: '/api/v1/upload?t=rechargeCenterGoods',
      currentUpload: 'summaryDesc',
      goodsTypeOpts: [
        {
          label: '月包',
          value: 0
        },
        {
          label: '短时包',
          value: 1
        },
        {
          label: '定向流量包',
          value: 2
        },
        {
          label: '推荐',
          value: 3
        }
      ],
      payTypeOpts: [
        {
          label: '话费支付',
          value: 0
        },
        {
          label: '直充',
          value: 1
        }
      ],
      summaryDescEditorOpts: {
        placeholder: '请输入商品摘要描述',
        theme: 'snow',
        modules: {
          toolbar: {
            container: toolbarOptions,  // 工具栏
            handlers: {
              'image': (value) => {
                  if (value) {
                    this.currentUpload = 'summaryDesc'
                    document.querySelector('#imageUpload input').click()
                  } else {
                    this.quill.format('image', false);
                  }
              }
            }
          }
        }
      },
      descEditorOpts: {
        placeholder: '请输入商品描述',
        // theme: 'bubble',
        theme: 'snow',
        modules: {
          toolbar: {
            container: toolbarOptions,  // 工具栏
            handlers: {
              'image': (value) => {
                  if (value) {
                    this.currentUpload = 'desc'
                    document.querySelector('#imageUpload input').click()
                  } else {
                    this.quill.format('image', false);
                  }
              }
            }
          }
        }
      }
    }
  },
  mounted() {
    // eslint-disable-next-line
    this.form._id = this.$route.query.id
    if (this.form._id) {
      this.findById()
    }
  },
  computed: {
    summaryDescEditor() {
      return this.$refs.summaryDescEditor.quill
    },
    descEditor() {
      return this.$refs.descEditor.quill
    }
  },
  methods: {
    goList() {
      this.$router.push({name: 'rechargeCenter'})
    },
    findById() {
      this.loading = true
      this.$service.RechargeCenterService
        .findById(this.form._id)
        .then(res => {
          if (res.status === 200 && res.data.code === 0) {
            this.form = res.data.data
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    saveForm() {
      this.payTypeOpts.forEach(item => {
        if(item.value === this.form.pay_type) {
          this.form.pay_type_desc = item.label
        }
      })
      this.goodsTypeOpts.forEach(item => {
        if(item.value === this.form.goods_type) {
          this.form.goods_type_desc = item.label
        }
      })
      this.loading = true
      this.$service.RechargeCenterService
        .saveGoods(this.form)
        .then(res => {
          if ((res.status === 200 || res.status === 201) && res.data.code === 0) {
            this.$message({
              message: '已保存',
              type: 'success'
            });
            this.goList()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 上传图片前
    beforeUpload() {
      this.uploadingImage = true
    },
    // 上传图片成功
    uploadSuccess(res) {
      // res为图片服务器返回的数据
      // 获取富文本组件实例
      // 如果上传成功
      
      let _quill = this.summaryDescEditor
      if (this.currentUpload === 'desc') {
        _quill = this.descEditor
      }
      if (res.code === 0 && res.data.path) {
        // 获取光标所在位置
        let length = _quill.getSelection().index
        // 插入图片  res.info为服务器返回的图片地址
        _quill.insertEmbed(length, 'image', this.$STAITC_HOST + res.data.path)
        // 调整光标到最后
        _quill.setSelection(length + 1)
      } else {
        this.$message.error('图片插入失败')
      }
      // loading动画消失
      this.uploadingImage = false
    },
    // 上传图片失败
    uploadError() {
      this.uploadingImage = false
      this.$message.error('图片插入失败')
    }
  },
}
</script>

<style lang="less" scoped>
.rechargeCenterGoods-form{
  max-width: 900px;
}
.mid-width{
  width: 500px;
}
.cover-carousels{
  width: 375px;
  zoom: .8;
}
.mobile-editor{
  width: 375px;
  padding-bottom: 66px;
  border: 6px solid #333;
  border-top-width: 16px;
  border-bottom-width: 16px;
  border-radius: 12px;
  zoom: 0.8;
  line-height: normal;
}
#imageUpload{
  height: 0px;
  overflow: hidden;
}
.thumbs{
  margin-top: 12px;
  .thumb{
    display:inline-block;
    position: relative;
    margin-right: 12px;
    border: 1px solid #a3a3a3;
    width: 36px;
    height: 36px;
    cursor: pointer;
    vertical-align: text-bottom;
    .thumb-image{
      width: inherit;
      height: inherit;
    }
    .remove-button{
      position: absolute;
      right: -7px;
      top: -16px;
      z-index: 1;
      color: Red;
    }
  }
  .btn-thumb{
    vertical-align: text-bottom;
    width: 38px;
    height: 38px;
    cursor: pointer;
    border: 1px solid #a3a3a3;
    padding: 0;
    margin: 0;
  }
}
#coverImageUpload{
  display: inline-block;
}
.skus{
  margin-bottom: 6px;
  margin-left: 12px;
}
.sku-infos{
  width: 500px;
  display: inline-block;
}
</style>