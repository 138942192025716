export default {
    init(vue) {
        return {
            getPlan(params) {
                return vue.$http({
                    method: 'GET',
                    url: '/api/v1/admanager/plan',
                    params: params
                })
            },
            postPlan(data) {
                return vue.$http({
                    method: 'POST',
                    url: '/api/v1/admanager/plan',
                    data: data
                })
            },
            putPlan(id, data) {
                return vue.$http({
                    method: 'PUT',
                    url: `/api/v1/admanager/plan/${id}`,
                    data: data
                })
            },
            deletePlan(id) {
                return vue.$http({
                    method: 'DELETE',
                    url: `/api/v1/admanager/plan/${id}`,
                })
            },
            getYdTaskList(params) {
                return vue.$http({
                    method: 'GET',
                    url: '/yd/pages/quest/list',
                    params: params
                })
            },
            saveYdTaskItem(data) {
                return vue.$http({
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    url: '/yd/pages/quest/save',
                    data: data
                })
            },
            getYdTransactNumGroupDay(params) {
                return vue.$http({
                    method: 'GET',
                    url: '/yd/pages/quest/listGroupDay',
                    params: params
                })
            },
            getYdTransactNumTopList(params) {
                return vue.$http({
                    method: 'GET',
                    url: '/yd/pages/quest/listTop',
                    params: params
                })
            },
        }
    }
}
