import Vue from 'vue'
import Router from 'vue-router'
import OrderRoute from './order.route'
import UserRoute from './user.route'
import GoodRoute from './good.route'
import TaskRoute from './task.route'
import QagameRoute from './qagame.route'
import AuthRoute from './auth.route'
import IdiomGameRoute from './idiomgame.route'
import PaidGoodRoute from './paidgood.route'
import PaidOrderRoute from './paidorder.route'
import RedPacketRoute from './redpacket.route'
import CarrierRoute from './carrier.route'
import NoticeRoute from './notice.route'
import SubMessageRoute from './submessage.route'
import StatsLogRoute from './statslog.route'
import SpotsGameRoute from './spotsgame.route'
import flowManagement from './flowManagement.route'
import onlineOrder from './onlineOrder.route'
import rechargeCenter from './rechargeCenter.route'
import transfer from './transfer.route'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/signin'
    },
    ...OrderRoute,
    ...UserRoute,
    ...GoodRoute,
    ...TaskRoute,
    ...QagameRoute,
    ...AuthRoute,
    ...IdiomGameRoute,
    ...PaidGoodRoute,
    ...PaidOrderRoute,
    ...RedPacketRoute,
    ...CarrierRoute,
    ...NoticeRoute,
    ...SubMessageRoute,
    ...StatsLogRoute,
    ...SpotsGameRoute,
    ...flowManagement,
    ...onlineOrder,
    ...rechargeCenter,
    ...transfer
  ]
})
