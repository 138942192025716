export default {
  init (vue) {
    return {
      query (params) {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/carriers/products/manager',
          params: params
        })
      },
      saveProduct(product) {
        let _method = 'POST'
        if (product._id) _method = 'PUT'
        return vue.$http({
          method: _method,
          url: '/api/v1/carriers/products',
          data: product
        })
      },
      queryProduct (productId) {
        return vue.$http({
          method: 'GET',
          url: `/api/v1/carriers/products/manager/${productId}`
        })
      },
      updateProductStatus (id, status) {
        return vue.$http({
          method: 'PUT',
          url: `/api/v1/carriers/products/manager/status/${id}`,
          data: {
            status: status
          }
        })
      }
    }
  }
}
