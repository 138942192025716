import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import moment from 'moment'
import axios from 'axios'
import router from './routes'
import Services from './services'

moment.locale('zh-cn')

Vue.config.productionTip = false

Vue.use(ElementUI, { size: 'small' });
Vue.use(Services);
Vue.prototype.$http = axios
Vue.prototype.$STAITC_HOST = process.env.QAGAME_STAITC_HOST || 'https://tlhw.oss-cn-shanghai.aliyuncs.com/'
// Vue.prototype.$QAGAME_STAITC_HOST = process.env.QAGAME_STAITC_HOST || 'http://192.168.50.113:8080/'
Vue.prototype.$moment = moment
Vue.prototype.$orderStatus = {
  '-1': '买家取消',
  '-2': '卖家取消',
  '-3': '系统取消',
  '-4': '失败',
  '0': '创建（等待发货）',
  '1': '已支付',
  '2': '已发货',
  '3': '交易完成',
  '4': '已评价'
}
Vue.prototype.$taskTypes = {
  'video': '视频广告',
  'wheel': '幸运大转盘',
  'invite': '邀请好友',
  'sign': '签到',
  'poster': '朋友圈分享',
  'gamead': '喵星人小世界',
  'gamead_fklm': '疯狂撸猫咪',
  'adquzq': '趣赚钱',
  'picword': '看图猜词'
}
// let axiosPath = 'http://192.168.1.24:3000/api-docs/'
// axios.defaults.baseURL = axiosPath
axios.interceptors.request.use((config) => {
  config.url = config.url + "?t=" + Date.now()
  config.headers['app-code'] = 'tlhw'
  let _token = localStorage.getItem('access_token')
  if (_token) {
    config.headers.Authorization = `Bearer ${_token}`
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(res => {
  return res
}, function (error) {
  if (error.response.status === 401
    && _vue.$router.name !== 'signin'
    && !/\/api\/auth\?/.test(error.config.url)
  ) {
    _vue.$message('登录超时，请重新登录')
    localStorage.removeItem('access_token')
    _vue.$router.push({
      name: 'signin'
    })
  }
  return Promise.reject(error)
})

const _vue = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
