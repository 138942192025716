export default {
  init (vue) {
    return {
      queryTemplates () {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/submessage/templates'
        })
      }
    }
  }
}
