export default {
  init (vue) {
    return {
      queryRecords (params) {
        return vue.$http({
          method: 'GET',
          url: `/api/v1/tasks/records`,
          params: params
        })
      },
      queryInviteBy(uid) {
        return vue.$http({
          method: 'GET',
          url: `/api/v1/tasks/inviteby/${uid}`
        })
      }
    }
  }
}
