<template lang="pug">
  .frame-header
    .tools
      .invite-search
        el-link(@click="inviteSearchPanelVisiable = true")
          i.el-icon-search
          | 邀请信息查询
        .invite-search-panel(v-show="inviteSearchPanelVisiable")
          .close(@click="inviteSearchPanelVisiable = false")
            i.el-icon-close
          el-form
            el-form-item(label="微信号")
              el-select(
                v-model="inviteWX",
                remote,
                filterable,
                reserve-keyword,
                placeholder="请输入关键词",
                :remote-method="handleWXFilterSearch",
                :loading="wxFitlerLoading",
                @change="handleInviteSearch"
              )
                el-option(
                  v-for="item in wxOptions"
                  :key="item._id"
                  :label="item.userInfo.nickName"
                  :value="item._id"
                )
          el-table(:data="inviteUsers", stripe)
            el-table-column(prop="create_date", label="注册日期")
              template(slot-scope="scope")
                span {{$moment(scope.row.create_date).calendar()}}
            el-table-column(label="头像")
              template(slot-scope="scope")
                el-image.avatar(
                  :src="scope.row.userInfo.avatarUrl",
                  fit="cover",
                  :preview-src-list="[scope.row.userInfo.avatarUrl]"
                )
            el-table-column(prop="userInfo.nickName", label="微信昵称")
            el-table-column(prop="coin", label="陀螺币")
            el-table-column(prop="sign", label="签到数")
            el-table-column(prop="invite", label="邀请成功数")
            el-table-column(label="省份城市")
              template(slot-scope="scope")
                span {{scope.row.userInfo.province + '，' +  scope.row.userInfo.city}}
</template>

<script>
export default {
  data() {
    return {
      inviteSearchPanelVisiable: false,
      inviteWX: null,
      wxFitlerLoading: false,
      wxOptions: [],
      userPageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      inviteUsers: []
    }
  },
  methods: {
    handleInviteSearch() {
      this.$service.TaskService
        .queryInviteBy(this.inviteWX)
        .then(res => {
          // eslint-disable-next-line
          if(res.data.code === 0 && res.data.data !== null) {
            this.inviteUsers = [res.data.data.user]
          } else {
            this.inviteUsers = []
          }
        })
    },
    handleWXFilterSearch(val) {
      if (val) {
        this.userPageModel.condition = {
          'userInfo.nickName': val
        }
      } else {
        this.userPageModel.condition = null
      }
      this.wxFitlerLoading = true
      this.$service.UserService
        .query(this.userPageModel)
        .then(res => {
          if (res.status === 200 && res.data.code === 0) {
            this.userPageModel.total = res.data.data.total
            this.wxOptions = res.data.data.users
          }
        })
        .finally(() => {
          this.wxFitlerLoading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.tools{
  display: flex;
  height: 50px;
  line-height: 50px;
  .invite-search{
    position: relative;
    .invite-search-panel{
      position: absolute;
      z-index: 99;
      left: 0;
      top: 40px;
      background-color: #fff;
      border-radius: 3px;
      box-shadow: 0 0 3px #333;
      width: 800px;
      min-height: 160px;
      padding: 12px;
      .close{
        position: absolute;
        font-size: 14px;
        right: 6px;
        top: -12px;
        z-index: 99;
        cursor: pointer;
      }
    }
  }
}
</style>