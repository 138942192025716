export default {
  init (vue) {
    return {
      query (params) {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/redpackets',
          params: params
        })
      },
      queryLogs (params) {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/redpackets/logs/redpacket',
          params: params
        })
      }
    }
  }
}
