export default {
  init(vue) {
    return {
      query(params) {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/spotsgame',
          params: params
        })
      },
      save(data) {
        return vue.$http({
          method: 'POST',
          url: '/api/v1/spotsgame',
          data: data
        })
      },
      removeItem(id) {
        return vue.$http({
          method: 'DELETE',
          url: `/api/v1/spotsgame/${id}`,
        })
      }
    }
  }
}
