<template lang="pug">
  .product-form
    el-form(:model="form", label-width="100px")
      el-form-item(label="商品名称")
          el-input.mid-width(v-model="form.name")
      el-form-item(label="图片")
        div
          el-image.cover-image(v-if="form.cover_img", :src="$STAITC_HOST + form.cover_img + '!710_jpeg_zoom'", fit="fill")
        div
          el-upload#coverImageUpload(
              :action="uploadUrl",
              :headers="uploadHeader",
              :show-file-list="false",
              accept="image/png, image/jpeg, image/gif",
              :on-success="coverUploadSuccess",
              :on-error="coverUploadError",
              :before-upload="coverBeforeUpload"
            )
              el-button(v-if="!form.cover_img").btn-thumb 上传商品图片
              el-button.btn-thumb(v-else) 替换商品图片
      el-form-item(label="类型")
        el-select(v-model="form.package_type", placeholder="请选择类型")
          el-option(label="流量包", value="FLOW")
          el-option(label="号卡", value="CARD")
      el-form-item(label="商品CODE")
        el-input(v-model="form.ref_goods_code", placeholder="移动后台查询对应GoodsCode，例如：TLHW302338282")
      el-form-item(label="输入运营商ID")
        el-input(v-model="form.ref_carrier_id", placeholder="输入运营商ID")
      el-form-item(label="商品价格")
        el-input(v-model="form.price", placeholder="输入价格，例如：30元/月")
      el-form-item(label="办理地址")
        el-input(v-model="form.product_url", placeholder="落地页链接地址")
      el-form-item(label="描述")
        el-input(v-model="form.desc", placeholder="描述", type="textarea")
      el-form-item
        el-button(@click="$router.go(-1)") 取消
        el-button(type="primary", :loading="loading", @click="saveForm") 确定
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  components: {
    quillEditor
  },
  data() {
    return {
      loading: false,
      uploadingImage: false,
      coverUploading: false,
      form: {
        name: '',
        desc: '',
        cover_img: '',
        ref_goods_code: '',
        ref_carrier_id: '',
        price: '',
        product_url: '',
        package_type: 'FLOW'
      },
      uploadHeader: {},
      uploadUrl: '/api/v1/upload?t=carrier'
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.queryProduct(this.$route.query.id)
    } 
  },
  methods: {
    queryProduct(id) {
      this.$service.CarrierService
        .queryProduct(id)
        .then(res => {
          if (res.data.code === 0) {
            this.form = res.data.data
          }
        })
    },
    coverUploadSuccess(res) {
      if (res.code === 0 && res.data.path) {
          this.form.cover_img = res.data.path
      } else {
          this.$message.error('上传失败')
      }
      this.coverUploading = false
    },
    coverUploadError() {
      this.coverUploading = false
      this.$message.error('上传失败')
    },
    coverBeforeUpload() {
      this.coverUploading = true
    },
    saveForm() {
      this.$service.CarrierService
        .saveProduct(this.form)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              message: '运营商商品信息已保存',
              type: 'success'
            });
            this.$router.push({
              name: 'CarrierProducts'
            })
          } else {
            this.$message({
              message: '保存过程中出现错误，message:' + res.data.message,
              type: 'error'
            });
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.product-form{
  max-width: 600px;
}
.mid-width{
  width: 500px;
}
.cover-carousels{
  width: 375px;
  zoom: .8;
}
.mobile-editor{
  width: 375px;
  height: 690px;
  padding-bottom: 66px;
  border: 6px solid #333;
  border-top-width: 16px;
  border-bottom-width: 16px;
  border-radius: 12px;
  zoom: 0.8;
  line-height: normal;
}
#imageUpload{
  height: 0px;
  overflow: hidden;
}
#coverImageUpload{
  display: inline-block;
}
.cover-image{
  width: 375px;
  height: auto;
}
</style>