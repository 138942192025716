<template lang="pug">
  .good-form
    el-form(:model="form", label-width="100px")
      el-form-item(label="商品名称")
          el-input.mid-width(v-model="form.name")
      el-form-item(label="商品图片")
        div
          el-image.cover-image(v-if="form.cover_image", :src="$STAITC_HOST + form.cover_image + '!710_jpeg_zoom'", fit="fill")
        div
          el-upload#coverImageUpload(
              :action="uploadUrl",
              :headers="uploadHeader",
              :show-file-list="false",
              accept="image/png, image/jpeg, image/gif",
              :on-success="coverUploadSuccess",
              :on-error="coverUploadError",
              :before-upload="coverBeforeUpload"
            )
              el-button(v-if="!form.cover_image").btn-thumb 上传商品图片
              el-button.btn-thumb(v-else) 替换商品图片
      el-form-item(label="市场价格")
        el-input-number(v-model="form.price", :step="10")
      el-form-item(label="陀螺币")
        el-input-number(v-model="form.coin", :step="100")
      el-form-item(label="虚拟销量")
        el-input-number(v-model="form.v_sales_count", :step="10")
      el-form-item
        div(v-loading="uploadingImage")
          el-upload#imageUpload(
            :action="uploadUrl",
            :headers="uploadHeader",
            :show-file-list="false",
            accept="image/png, image/jpeg, image/gif",
            :on-success="uploadSuccess",
            :on-error="uploadError",
            :before-upload="beforeUpload"
          )
          quill-editor.mobile-editor(v-model="form.desc", ref="descEditor", :options="descEditorOpts")
            #toolbar(slot="toolbar")
              span.ql-formats
                select.ql-font
                select.ql-size
              span.ql-formats
                button.ql-bold
                button.ql-italic
                button.ql-underline
                button.ql-strike
              span.ql-formats
                button.ql-script(value="sub")
                button.ql-script(value="super")
              span.ql-formats
                button.ql-link
                button.ql-image
                button.ql-video
              span.ql-formats
                button.ql-list(value="ordered")
                button.ql-list(value="bullet")
              span.ql-formats
                select.ql-color
                select.ql-background
              span.ql-formats
                select.ql-align
      el-form-item
        el-button(@click="$router.go(-1)") 取消
        el-button(type="primary", :loading="loading", @click="saveForm") 确定
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
export default {
  components: {
    quillEditor
  },
  data() {
    return {
      loading: false,
      uploadingImage: false,
      coverUploading: false,
      form: {
        name: '',
        desc: '',
        cover_image: '',
        channel: 'WX_MINIAPP',
        sales_count: 0,
        v_sales_count: 0,
        status: 0,
        price: 0,  // 市场价
        coin: 0,  // 换购所需陀螺币
        stock: 0  // 库存，2021-7-7 暂时无效 by Karl
      },
      uploadHeader: {},
      uploadUrl: '/api/v1/upload?t=good',
      descEditorOpts: {
        placeholder: '请输入商品描述',
        // theme: 'bubble',
        theme: 'snow',
        modules: {
          toolbar: {
            container: '#toolbar',  // 工具栏
            handlers: {
              'image': (value) => {
                  if (value) {
                    this.currentUpload = 'content'
                    document.querySelector('#imageUpload input').click()
                  } else {
                    this.quill.format('image', false);
                  }
              }
            }
          }
        }
      }
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.getGood(this.$route.query.id)
    } 
  },
  methods: {
    getGood(id) {
      this.$service.GoodService
        .getById(id)
        .then(res => {
          if (res.data.code === 0) {
            this.form = res.data.data
            if (this.form.desc_img) {
              this.form.desc = this.form.desc ? 
                                this.form.desc + this.form.desc_img
                                : this.form.desc_img
              let _quill = this.$refs.descEditor.quill
              // 获取光标所在位置
              let length = this.form.desc ? this.form.desc.length : 0
              // 插入图片  res.info为服务器返回的图片地址
              _quill.insertEmbed(length, 'image', this.form.desc_img)
              // 调整光标到最后
              _quill.setSelection(length + 1)

              this.form.desc_img = ''
            }
          }
        })
    },
    // 上传图片前
    beforeUpload() {
      this.uploadingImage = true
    },
    // 上传图片成功
    uploadSuccess(res) {
      // res为图片服务器返回的数据
      // 获取富文本组件实例
      // 如果上传成功
      if (res.code === 0 && res.data.path) {
        let _quill = this.$refs.descEditor.quill
        // 获取光标所在位置
        let length = this.$refs.descEditor.quill.getSelection().index
        // 插入图片  res.info为服务器返回的图片地址
        _quill.insertEmbed(length, 'image', this.$STAITC_HOST + res.data.path + '!710_jpeg_zoom')
        // 调整光标到最后
        _quill.setSelection(length + 1)
      } else {
        this.$message.error('图片插入失败')
      }
      // loading动画消失
      this.uploadingImage = false
    },
    // 上传图片失败
    uploadError() {
      this.uploadingImage = false
      this.$message.error('图片插入失败')
    },
    coverUploadSuccess(res) {
      if (res.code === 0 && res.data.path) {
          this.form.cover_image = res.data.path
      } else {
          this.$message.error('上传失败')
      }
      this.coverUploading = false
    },
    coverUploadError() {
      this.coverUploading = false
      this.$message.error('上传失败')
    },
    coverBeforeUpload() {
      this.coverUploading = true
    },
    saveForm() {
      this.$service.GoodService
        .save(this.form)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              message: '商品信息已保存',
              type: 'success'
            });
            this.$router.push({
              name: 'goods'
            })
          } else {
            this.$message({
              message: '保存过程中出现错误，message:' + res.data.message,
              type: 'error'
            });
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.good-form{
  max-width: 900px;
}
.mid-width{
  width: 500px;
}
.cover-carousels{
  width: 375px;
  zoom: .8;
}
.mobile-editor{
  width: 375px;
  height: 690px;
  padding-bottom: 66px;
  border: 6px solid #333;
  border-top-width: 16px;
  border-bottom-width: 16px;
  border-radius: 12px;
  zoom: 0.8;
  line-height: normal;
}
#imageUpload{
  height: 0px;
  overflow: hidden;
}
#coverImageUpload{
  display: inline-block;
}
.cover-image{
  width: 375px;
  height: auto;
}
</style>