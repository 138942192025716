export default {
  init (vue) {
    return {
      query (params) {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/paidgoods/manager',
          params: params
        })
      },
      save (paidgood) {
        let _method = 'POST'
        if (paidgood._id) _method = 'PUT'
        return vue.$http({
          method: _method,
          url: '/api/v1/paidgoods',
          data: paidgood
        })
      },
      getById (paidgoodId) {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/paidgoods/manager/paidgood',
          params: {
            id: paidgoodId
          }
        })
      },
      updateStatus (paidgoodId, status) {
        return vue.$http({
          method: 'PUT',
          url: '/api/v1/paidgoods/manager/paidgood/status',
          data: {
            _id: paidgoodId,
            status: status
          }
        })
      }
    }
  }
}
