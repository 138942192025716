<template lang="pug">
  el-dialog(title="兑换规则分组", :visible.sync="dialogVisible")
    el-form(label-width="80px")
      el-form-item(label="分组")
        el-select(v-model="selectGroup", placeholder="请选择分组")
          el-option(
            v-for="item in groups",
            :key="item._id",
            :label="item.name",
            :value="item._id"
          )
    div(slot="footer")
      el-button(@click="dialogVisible = false") 取消
      el-button(type="warning", @click="cancelGroup", :loading="loading") 去掉兑换规则
      el-button(type="primary", @click="saveForm", :loading="loading") 确定
</template>

<script>
export default {
  name: 'IntentionalRuleGroupSelector',
  props: ['good', 'group'],
  data() {
    return {
      dialogVisible: false,
      loading: false,
      groups: [],
      selectGroup: null
    }
  },
  watch: {
    dialogVisible(){
      if (this.dialogVisible) {
        this.selectGroup = null
        this.$service.IntentionalRuleGroupService
          .query()
          .then(res => {
            if (res.status === 200 && res.data.code === 0 && res.data.data) {
              this.groups = res.data.data
              if (this.group) {
                this.selectGroup = this.group._id + ''
              }
            }
          })
      }
    }
  },
  methods: {
    saveForm() {
      this.loading = true
      this.$service.GoodService
        .updateRuleGroup(this.good, this.selectGroup)
        .then(res => {
          if (res.status === 200 && res.data.code === 0) {
            this.$message({
              message: '分组信息已保存',
              type: 'success'
            });
            this.hide()
            this.$emit('saved')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    cancelGroup() {
      this.loading = true
      this.$service.GoodService
        .updateRuleGroup(this.good, 'remove')
        .then(res => {
          if (res.status === 200 && res.data.code === 0) {
            this.$message({
              message: '分组信息已去掉',
              type: 'success'
            });
            this.hide()
            this.$emit('saved')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    show() {
      this.dialogVisible = true
    },
    hide() {
      this.dialogVisible = false
    }
  },
}
</script>