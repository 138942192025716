<template lang="pug">
  el-dialog(title="收货地址", :visible.sync="dialogVisible")
    el-form(:model="form", label-width="80px")
      el-form-item(label="快递单号")
        el-input(v-model="form.num" autocomplete="off")
      el-form-item(label="快递公司")
        el-select(v-model="form.company", placeholder="请选择快递公司")
          el-option(
            v-for="item in expressCompanys",
            :key="item.value",
            :label="item.label",
            :value="item.value"
          )
    div(slot="footer")
      el-button(@click="dialogVisible = false") 取消
      el-button(type="primary", @click="saveForm", :loading="loading") 确定
</template>

<script>
export default {
  name: 'OrderExpressForm',
  props: ['orderId'],
  data() {
    return {
      dialogVisible: false,
      loading: false,
      form: {},
      labelWidth: '130px',
      expressCompanys: [
        {
          value: '申通快递', label: '申通快递'
        },
        {
          value: '百世快递', label: '百世快递'
        },
        {
          value: '邮政包裹信件', label: '邮政包裹信件'
        },
        {
          value: '邮政EMS', label: '邮政EMS'
        },
        {
          value: '中通快递', label: '中通快递'
        },
        {
          value: '韵达速递', label: '韵达速递'
        },
        {
          value: '天天快递', label: '天天快递'
        },
        {
          value: '圆通快递', label: '圆通快递'
        },
        {
          value: '京东物流', label: '京东物流'
        },
        {
          value: '顺丰速运', label: '顺丰速运'
        },
        {
          value: '龙邦快递', label: '龙邦快递'
        },
        {
          value: '极兔速递', label: '极兔速递'
        }
      ]
    }
  },
  watch: {
    dialogVisible(){
      if (this.dialogVisible) {
        this.$service.OrderService
          .findById(this.orderId)
          .then(res => {
            if (res.status === 200 && res.data.code === 0 && res.data.data) {
              this.form = res.data.data.express
            }
          })
      }
    }
  },
  methods: {
    saveForm() {
      this.loading = true
      this.$service.OrderService
        .updateExpress(this.orderId, this.form)
        .then(res => {
          if (res.status === 200 && res.data.code === 0) {
            this.$message({
              message: '快递信息已保存',
              type: 'success'
            });
            this.hide()
            this.$emit('saved')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    show() {
      this.dialogVisible = true
    },
    hide() {
      this.dialogVisible = false
    }
  },
}
</script>