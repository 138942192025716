export default {
  init (vue) {
    return {
      query (params) {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/orders',
          params: params
        })
      },
      findById (orderID) {
        return vue.$http.get(`/api/v1/orders/${orderID}`)
      },
      exportsData(params) {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/orders',
          params: params
        })
      },
      updateExpress(orderID, express) {
        return vue.$http({
          method: 'PUT',
          url: `api/v1/orders/${orderID}/express`,
          data: {
            express: express
          }
        })
      },
      updateStatus(orderID, status) {
        return vue.$http({
          method: 'PUT',
          url: `api/v1/orders/${orderID}/status`,
          data: {
            status: status
          }
        })
      },
      sendMsg(orderID, templateID) {
        return vue.$http({
          method: 'POST',
          url: `api/v1/orders/${orderID}/sendmsg`,
          data: {
            tid: templateID
          }
        })
      },
    }
  }
}
