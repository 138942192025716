export default {
  init (vue) {
    return {
      query (params) {
        return vue.$http({
          method: 'GET',
          url: '/api/v1/notices',
          params: params
        })
      },
      save(data) {
        return vue.$http({
          method: 'POST',
          url: '/api/v1/notices',
          data: data
        })
      }
    }
  }
}
