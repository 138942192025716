export default {
  init (vue) {
    return {
      queryLogs (params) {
        return vue.$http({
          method: 'GET',
          url: `/api/v1/idiomgame/logs`,
          params: params
        })
      }
    }
  }
}
