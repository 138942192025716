<template lang="pug">
  .orders
    el-row
      el-col(:span="16")
        el-form(:inline="true")
          el-form-item(label="微信号")
            el-select(
              v-model="orderByWX",
              remote,
              filterable,
              reserve-keyword,
              placeholder="请输入关键词",
              :remote-method="handleWXFilterSearch",
              :loading="wxFitlerLoading",
              @change="handleWXSelected"
            )
              el-option(
                v-for="item in wxOptions"
                :key="item._id"
                :label="item.userInfo.nickName"
                :value="item._id"
              )
                el-image.select-avatar(:src="item.userInfo.avatarUrl")
                span {{item.userInfo.nickName}}
          el-form-item(label="订单状态")
            el-select(v-model="pageModel.condition.status", placeholder="请选择订单状态", @change="query(1)")
              el-option(label="全部", value="all")
              el-option(
                v-for="(item, key) in $orderStatus",
                :key="key",
                :label="item",
                :value="key * 1"
              )
          el-form-item(label="收货人")
            el-col(:span="10")
              el-input(type="text", placeholder="请输入收货人姓名", v-model="pageModel.condition.expressname")
            el-col(:span="10")
              el-input(type="text", placeholder="请输入收货人电话", v-model="pageModel.condition.express_phone")
            el-col(:span="4")
              el-button(@click="query(1)") 查询
    el-table(:data="orders", stripe)
      el-table-column(prop="create_date", label="下单日期")
        template(slot-scope="scope")
          span {{$moment(scope.row.create_date).calendar()}}
      el-table-column(label="商品", width="160")
        template(slot-scope="scope")
          span {{scope.row.paidgood_details.name}}
          el-tag {{scope.row.paidgood_details.sku.name}}
      el-table-column(prop="user.userInfo.nickName", label="微信号")
      el-table-column(label="头像")
        template(slot-scope="scope")
          el-image.avatar(
            :src="scope.row.user.userInfo.avatarUrl",
            fit="cover",
            :preview-src-list="[scope.row.user.userInfo.avatarUrl]"
          )
      el-table-column(label="收货人", width="200")
        template(slot-scope="scope")
          div(v-if="scope.row.express")
            div 收货人：{{scope.row.express.receive_name}}
            div 收货电话：{{scope.row.express.phone}}
            div 所在地区：{{`${scope.row.express.province}${scope.row.express.city}${scope.row.express.area}`}}
            div 详细地址：{{scope.row.express.street}}
            div 订单备注：{{scope.row.desc || '-'}}
      el-table-column(label="快递信息")
        template(slot-scope="scope")
          span(v-if="scope.row.express")
            span(v-if="scope.row.express.company") [{{scope.row.express.company}}]
            | {{scope.row.express.num}}
      el-table-column(label="订单状态", width="130")
        template(slot-scope="scope")
          el-tag(size="mini") {{$orderStatus[scope.row.status.toString()]}}
          div ¥{{scope.row.paidgood_details.sku.sale_price}}
      el-table-column(label="操作")
        template(slot-scope="scope")
          el-button-group
            el-button(type="primary", size="mini", @click="handleExpressModify(scope.row)") 快递
            el-button(type="primary", size="mini", @click="handleStautsModify(scope.row)") 状态
            el-button(v-if="scope.row.express && !scope.row.express.shipment_msg_sended", type="warning", size="mini", @click="handleSendMsg(scope.row)") 发货通知
    el-pagination(
      background,
      :current-page="pageModel.pageNo",
      layout="prev, pager, next, total",
      :total="pageModel.total"
      @current-change="query"
    )
    PaidOrderExpressForm(ref="expressForm", :orderId="modifyOrder._id", @saved="query")
    PaidOrderStatusForm(ref="statusForm", :orderId="modifyOrder._id", @saved="query")
</template>

<script>
import PaidOrderExpressForm from '../../components/paidorder/express.form.vue'
import PaidOrderStatusForm from '../../components/paidorder/status.form.vue'

export default {
  components: {
    PaidOrderExpressForm,
    PaidOrderStatusForm
  },
  data() {
    return {
      orders: [],
      modifyOrder: {},
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        condition: {
          user: null,
          status: 'all',
          expressname: null,
          express_phone: null
        }
      },
      userPageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      orderByWX: null,
      wxFitlerLoading: false,
      wxOptions: []
    }
  },
  mounted() {
    this.query(1)
  },
  methods: {
    query(pageNo) {
      let _condition = {}
      if (this.pageModel.condition.user) {
        _condition.user = this.pageModel.condition.user
      }
      if (this.pageModel.condition.status !== 'all') {
        _condition.status = this.pageModel.condition.status
      }
      if (this.pageModel.condition.expressname) {
        _condition.expressname = this.pageModel.condition.expressname
      }
      if (this.pageModel.condition.express_phone) {
        _condition.express_phone = this.pageModel.condition.express_phone
      }
      this.pageModel.pageNo = pageNo || this.pageModel.pageNo
      this.$service.PaidOrderService
        .query({
          pageNo: this.pageModel.pageNo,
          pageSize: this.pageModel.pageSize,
          condition: _condition
        })
        .then(res => {
          if (res.status === 200) {
            this.pageModel.total = res.data.data.total
            res.data.data.orders.forEach(item => {
              if (!item.user.userInfo) {
                item.user.userInfo = {}
              }
            })
            this.orders = res.data.data.orders
          }
        })
    },
    handleExpressModify(order) {
      this.modifyOrder = order
      this.$refs.expressForm.show()
    },
    handleStautsModify(order) {
      this.modifyOrder = order
      this.$refs.statusForm.show()
    },
    handleSendMsg(order) {
      if (!order.express) {
        this.$message({
          message: '没有物流信息无法推送消息',
          type: 'warning'
        });
      } else if (order.express.shipment_msg_sended) {
        this.$message({
          message: '发货信息已推送过',
          type: 'warning'
        });
      } else {
        this.$service.PaidOrderService
          .sendMsg(order._id)
          .then(res => {
            this.query();
            if (res.status === 200 && res.data.code === 0) {
              this.$message({
                message: '消息已发送',
                type: 'success'
              });
            }
          })
          .catch(err => {
            this.query();
            this.$message({
                message: err.message,
                type: 'error'
              });
          })
      }
    },
    handleWXFilterSearch(val) {
      if (val) {
        this.userPageModel.condition = {
          'userInfo.nickName': val
        }
      } else {
        this.userPageModel.condition = null
      }
      this.wxFitlerLoading = true
      this.$service.UserService
        .query(this.userPageModel)
        .then(res => {
          this.wxFitlerLoading = false
          if (res.status === 200 && res.data.code === 0) {
            this.userPageModel.total = res.data.data.total
            this.wxOptions = res.data.data.users
          }
        })
        .catch(() => {
          this.wxFitlerLoading = false
        })
    },
    handleWXSelected(val) {
      this.pageModel.condition.user = val
      this.query(1)
    }
  },
}
</script>

<style lang="less" scope>
</style>