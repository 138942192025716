<template lang="pug">
  el-dialog(title="修改陀螺币", :visible.sync="dialogVisible")
    el-form(:model="form", label-width="80px")
      el-form-item(label="陀螺币")
        el-input-number(v-model="form.coin", label="陀螺币", controls-position="right", :step="100")
    div(slot="footer")
      el-button(@click="dialogVisible = false") 取消
      el-button(type="primary", @click="saveForm", :loading="loading") 确定
</template>

<script>
export default {
  name: 'GoodCoinForm',
  props: ['goodId'],
  data() {
    return {
      dialogVisible: false,
      loading: false,
      form: {
        coin: 9999
      },
      labelWidth: '130px'
    }
  },
  watch: {
    dialogVisible(){
      if (this.dialogVisible) {
        this.form.status = null
        this.$service.GoodService
          .findById(this.goodId)
          .then(res => {
            if (res.status === 200 && res.data.code === 0 && res.data.data) {
              this.form = res.data.data
            }
          })
      }
    }
  },
  methods: {
    saveForm() {
      this.loading = true
      this.$service.GoodService
        .updateCoin(this.goodId, this.form.coin)
        .then(res => {
          if (res.status === 200 && res.data.code === 0) {
            this.$message({
              message: '陀螺币已更新',
              type: 'success'
            });
            this.hide()
            this.$emit('saved')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    show() {
      this.dialogVisible = true
    },
    hide() {
      this.dialogVisible = false
    }
  },
}
</script>