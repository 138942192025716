import PaidGoods from '../views/paidgoods/list.vue'
import PaidGoodForm from '../views/paidgoods/form.vue'

export default [
  {
    path: '/paidgoods',
    name: "paidgoods",
    component: PaidGoods,
  },
  {
    path: '/paidgoods/form',
    name: "paidgoodForm",
    component: PaidGoodForm,
  }
]