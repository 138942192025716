import OrderService from './order.service'
import UserService from './user.service'
import GoodService from './good.service'
import IntentionalRuleGroupService from './intentionalRuleGroup.service'
import TaskService from './task.service'
import QagameService from './qagame.service'
import AuthService from './auth.service'
import IdiomGameService from './idiomgame.service'
import PaidGoodService from './paidgood.service'
import PaidOrderService from './paidorder.service'
import RedPacketService from './redpacket.service'
import CarrierService from './carrier.service'
import CommonService from './common.service'
import NoticeService from './notice.service'
import SubMessageService from './submessage.service'
import StatsLogService from './statslog.service'
import SpotsGameService from './spotsgame.service'
import AdmanagerService from './admanager.service'
import OnlineOrderService from './onlineOrder.service'
import RechargeCenterService from './rechargeCenter.service'
import TransferService from './transfer.service'
import EventTrackService from './eventTrack.service'

export default {
  install(Vue) {
    Object.defineProperties(Vue.prototype, {
      $service: {
        get() {
          return {
            OrderService: OrderService.init(this),
            UserService: UserService.init(this),
            GoodService: GoodService.init(this),
            IntentionalRuleGroupService: IntentionalRuleGroupService.init(this),
            TaskService: TaskService.init(this),
            QagameService: QagameService.init(this),
            AuthService: AuthService.init(this),
            IdiomGameService: IdiomGameService.init(this),
            PaidGoodService: PaidGoodService.init(this),
            PaidOrderService: PaidOrderService.init(this),
            RedPacketService: RedPacketService.init(this),
            CarrierService: CarrierService.init(this),
            CommonService: CommonService.init(this),
            NoticeService: NoticeService.init(this),
            SubMessageService: SubMessageService.init(this),
            StatsLogService: StatsLogService.init(this),
            SpotsGameService: SpotsGameService.init(this),
            AdmanagerService: AdmanagerService.init(this),
            OnlineOrderService: OnlineOrderService.init(this),
            RechargeCenterService: RechargeCenterService.init(this),
            TransferService: TransferService.init(this),
            EventTrackService: EventTrackService.init(this)
          }
        }
      }
    })
  }
}
