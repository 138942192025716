import Notices from '../views/notices/list.vue'
import NoticeForm from '../views/notices/form.vue'
export default [
  {
    path: '/notices',
    name: "notices",
    component: Notices
  },
  {
    path: '/notices/form',
    name: "noticesForm",
    component: NoticeForm
  }
]