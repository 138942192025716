<template lang="pug">
  .statslogs
    el-table(:data="logs", stripe, v-loading="loading")
      el-table-column(label="日期", width="100px")
        template(slot-scope="scope")
          span {{$moment(scope.row.create_date).calendar()}}
      el-table-column(prop="tag", label="标签", width="100px")
        template(slot-scope="scope")
          el-tag(type="primary") {{scope.row.tag}}
      el-table-column(prop="memo", label="日志内容")
    el-pagination(
      background,
      :current-page="pageModel.pageNo",
      layout="prev, pager, next, total",
      :total="pageModel.total"
      @current-change="query"
    )
</template>

<script>
export default {
  name: 'StatsLogs',
  data() {
    return {
      logs: [],
      loading: false,
      pageModel: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  mounted() {
    this.query()
  },
  methods: {
    query(pageNo) {
      this.pageModel.pageNo = pageNo || this.pageModel.pageNo
      this.loading = true
      this.$service.StatsLogService
        .query({
          pageNo: this.pageModel.pageNo,
          pageSize: this.pageModel.pageSize
        })
        .then(res => {
          this.logs = res.data.data.logs
          this.pageModel.total = res.data.data.total
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
</style>